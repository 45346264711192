import React, { useState, useEffect } from "react";
import {
  Link as Linki18,
  useI18next,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";
import styles from "./Navbar.module.scss";
import { motion } from "framer-motion";
import { container, item } from "./framer";

import logoNav from "../../../assets/svg/logoNav.svg";
import LogoBlue from "../../../assets/svg/LogoBlue.svg";

import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { isBrowser } from "@utils/isBrowser";
// import FloatingSeeDemo from "../../molecules/FloatingSeeDemo";

const languageOptions = ["en", "es", "pr"];

const Navbar = (props) => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();
  const { originalPath } = useI18next();
  const [showNav, setShowNav] = useState(true);
  const [y, setY] = useState(0);
  const [path, setPath] = useState([]);
  const [isMobile, setMobile] = useState(false);
  const { language } = useI18next();

  const getPathFromLanguage = (lang) => {
    if(lang === "es"){
      return "https://blog.n5now.com/";
    } else if (lang === "en") {
      return "https://blog.n5now.com/en";
    } else if (lang === "pr") {
      return "https://blog.n5now.com/pt-br/";
    }
    return ;
  }

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 760);
  };

  useEffect(() => {
   if (isBrowser) {
    setMobile(window.innerWidth <= 760)
     window.addEventListener("resize", handleWindowSizeChange);
  
      return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      };
   } 
  });

  useEffect(() => {
    handleStyleNav();
  }, []);

  const handleStyleNav = () => {
    const data = { ...props.location };

    let pathArr = data.pathname.split("/");
    if (!pathArr.includes("pr") && !pathArr.includes("es")) {
      const newPath = data.pathname.split("/");
      const final = newPath.filter((e) => e !== "");
      final.push("en");
      if (
        !pathArr.includes("platform") &&
        !pathArr.includes("services") &&
        !pathArr.includes("content") &&
        !pathArr.includes("work")
      ) {
        final.push("home");
      }
      setPath(final);
    } else if (
      !pathArr.includes("platform") &&
      !pathArr.includes("services") &&
      !pathArr.includes("content") &&
      !pathArr.includes("work")
    ) {
      const final = pathArr.filter((e) => e !== "");
      final.push("home");
      setPath(final);
    } else {
      const final = pathArr.filter((e) => e !== "");
      setPath(final);
    }
  };

  const links = [
    { name: t("MENU.HOME"), to: "/", key: "home" },
    { name: t("MENU.PLATFORM"), to: "/platform/", key: "platform" },
    { name: t("MENU.SERVICES"), to: "/services/", key: "services" },
    { name: t("MENU.CONTENT"), to: "/content/", key: "content" },

    {
      name: t("MENU.WORK_WITH_US"),
      to: "/work/",
      partiallyActive: true,
      key: "work",
    },
    {
      name: t("MENU.FINANCIAL_INCLUSION"),
      to: "/financial-access",
      key: "financial_access",
    },
  ];

  useEffect(() => {
    if (breakpoints.md) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [breakpoints.md]);

  useEffect(() => {
    if (isBrowser && typeof document !== "undefined") {
      const body = document.querySelector("body");
      if (showNav && breakpoints.sm) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "visible";
      }
    }
  }, [showNav]);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop =
        isBrowser &&
        typeof document !== "undefined" &&
        (window.pageYOffset || document.documentElement.scrollTop);

      if (
        Math.sign(scrollTop) !== -1 &&
        Math.sign(lastScrollTop) !== -1 &&
        scrollTop > lastScrollTop
      ) {
        setY(-80);
      } else {
        setY(0);
      }

      lastScrollTop = scrollTop;
    };
    isBrowser && window.addEventListener("scroll", handleScroll);

    return () => {
      isBrowser && window.removeEventListener("scroll", handleScroll);
    };
  }, [showNav, breakpoints.md]);

  return (
    <motion.header
      animate={{ y }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
      className={`${styles.header} ${
        props.platform && !props.ideasVisible ? styles.platform : ""
      }`}
      style={{
        height: breakpoints.md && showNav ? "100%" : "64px",
        transition: ".1s",
      }}
    >
      <nav
        id="navbar"
        className={styles.navbar}
        style={{
          height: breakpoints.md && showNav ? "100%" : "64px",
          transition: ".1s",
        }}
      >
        <div className={styles.menu}>
          <div
            onClick={() => setShowNav(!showNav)}
            onPointerEnter={(e) => e.preventDefault()}
          >
            {!showNav ? (
              <Menu
                fill={
                  props.platform && !props.ideasVisible
                    ? "rgba(0, 0, 0, 0.5)"
                    : props.content
                    ? "#FF5733"
                    : "#fff"
                }
              />
            ) : (
              <CloseMenu
                fill={
                  props.platform && !props.ideasVisible
                    ? "rgba(0, 0, 0, 0.5)"
                    : "#fff"
                }
                width={18}
                height={18}
              />
            )}
          </div>

          {!showNav && (
            <Link to="/">
              <motion.img
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 1, duration: 1, stiffness: 20 }}
                src={props.platform && !props.ideasVisible ? LogoBlue : logoNav}
                className={styles.logoMob}
                alt="logoMob"
              />
            </Link>
          )}
        </div>

        <div
          onMouseEnter={props.handleCursor}
          onMouseLeave={props.handleCursor}
          className={styles.mtes}
        >
          <Linki18 to="/" state={{ once: 1 }}>
            <motion.img
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1, duration: 1, stiffness: 20 }}
              src={props.platform && !props.ideasVisible ? LogoBlue : logoNav}
              className="m0"
              alt="N5 Logo"
            />
          </Linki18>
        </div>

        <div className={showNav ? styles.transform0 : styles.transform40}>
          <motion.ul
            variants={container}
            initial="hidden"
            animate={showNav ? "visible" : "hidden"}
            className={
              props.platform && !props.ideasVisible
                ? styles.linksPlatform
                : styles.links
            }
          >
            {links.map(({ name, to, key }, index) => {
              return (
                <motion.li key={name} variants={item}>
                  {
                   to === "/content/" ? 
                   <a
                   onMouseEnter={props.handleCursor}
                   onMouseLeave={props.handleCursor}
                   href={getPathFromLanguage(language)}
                   state={{
                     once: 1,
                   }}
                   key={index}
                   className={
                     path.includes(key)
                       ? props.platform && !props.ideasVisible
                         ? styles.black
                         : styles.white
                       : props.platform && !props.ideasVisible
                       ? styles.black
                       : styles.lightWhite
                   }
                   style={{
                     fontSize: breakpoints.md && showNav ? "28px" : "14px",
                   }}
                 >
                   {name}
                 </a> : 
                   
                   
                  
                   <Linki18
                   onMouseEnter={props.handleCursor}
                   onMouseLeave={props.handleCursor}
                   to={to}
                   state={{
                     once: 1,
                   }}
                   key={index}
                   className={
                     path.includes(key)
                       ? props.platform && !props.ideasVisible
                         ? styles.black
                         : styles.white
                       : props.platform && !props.ideasVisible
                       ? styles.black
                       : styles.lightWhite
                   }
                   style={{
                     fontSize: breakpoints.md && showNav ? "28px" : "14px",
                   }}
                 >
                   {name}
                 </Linki18>
                  }
                </motion.li>
              );
            })}
            {/* <motion.li variants={item}>
              <FloatingSeeDemo />
            </motion.li> */}
          </motion.ul>
        </div>

        <div className={showNav ? styles.transform0 : styles.transform40}>
          <motion.ul
            variants={container}
            initial="hidden"
            animate={showNav ? "visible" : "hidden"}
            className={
              props.platform && !props.ideasVisible
                ? styles.textLanguagePlatform
                : styles.textLanguage
            }
          >
            {!props.apply &&
              languageOptions.map((language) => {
                return (
                  <motion.li key={language} variants={item}>
                    <Linki18
                      onMouseEnter={props.handleCursor}
                      onMouseLeave={props.handleCursor}
                      to={originalPath}
                      state={{ once: 1 }}
                      language={language}
                      className={
                        path.includes(language)
                          ? props.platform && !props.ideasVisible
                            ? styles.black
                            : styles.white
                          : props.platform && !props.ideasVisible
                          ? styles.lightBlack
                          : styles.lightWhite
                      }
                      style={{
                        fontSize: breakpoints.md && showNav ? "20px" : "14px",
                      }}
                    >
                      {t(language) !== "pr" ? t(language) : "pt"}
                    </Linki18>
                  </motion.li>
                );
              })}
          </motion.ul>
        </div>
      </nav>
    </motion.header>
  );
};

function Menu({ fill = "#fff", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={fill}
        className="prefix__b"
        d="M1.5 3.5h21v1.2h-21zM1.5 11.7h21v1.2h-21zM1.5 19.9h21v1.2h-21z"
      />
    </svg>
  );
}

function CloseMenu({ fill = "#fff", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={512}
      height={512}
      viewBox="0 0 496.096 496.096"
      {...props}
    >
      <path
        d="M259.41 247.998L493.754 13.654a8 8 0 000-11.312 8 8 0 00-11.312 0L248.098 236.686 13.754 2.342A8 8 0 002.442 13.654l234.344 234.344L2.442 482.342a8 8 0 00-.196 11.312 8 8 0 0011.508 0L248.098 259.31l234.344 234.344a8 8 0 0011.312-.196 8 8 0 000-11.116L259.41 247.998z"
        fill={fill}
        data-original="#000000"
        xmlns="http://www.w3.org/2000/svg"
      />
    </svg>
  );
}

export default Navbar;
